import React, { useState, useEffect } from 'react';

import { Box, Fade, Stack } from '@mui/material';

import ImageSection from '../Image';
import TextLinkSection from '../TextLinks';

export default function Carousel(props) {
  const { data, fadeInterval = 5000 } = props;

  const [slideIndex, setSlideIndex] = useState(0);

  // AUTO slide change
  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % data.slides.length);
    }, fadeInterval);

    return () => clearInterval(interval);
  }, []);

  // HANDLE manual slide change
  const handleChange = (index) => {
    setSlideIndex(index);
  };

  const Selector = () => (
    <Stack
      spacing={1}
      direction="row"
      marginTop="-60px"
      marginBottom="30px"
      justifyContent="center"
    >
      {data.slides.map((_, index) => (
        <Box
          key={index}
          width="32px"
          height="16px"
          borderRadius="8px"
          sx={{ cursor: 'pointer' }}
          onClick={() => handleChange(index)}
          {...(slideIndex === index ? { bgcolor: '#FFF' } : { border: '5px solid #FFF' })}
        />
      ))}
    </Stack>
  );

  return (
    <Box sx={{ display: 'grid' }}>
      {data.slides.map((slide, index) => (
        <Fade
          key={index}
          mountOnEnter
          unmountOnExit
          in={slideIndex === index}
          sx={{ gridArea: '1 / 1' }}
          timeout={{ enter: 1000, exit: 1000, appear: 1000 }}
        >
          <Box>
            <Box>
              <ImageSection data={{ image: slide.image }} />
              <Selector />
            </Box>

            <TextLinkSection data={slide} />
          </Box>
        </Fade>
      ))}
    </Box>
  );
}
