import React from 'react';

import ReactMarkdown from 'react-markdown';

import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PageLink from '../../components/PageLink';

export default function TextImage(props) {
  const { data } = props;
  const { title, links, image, bgcolor, description } = data;

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isXSmall = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container p={2} gap={1} wrap={isLarge ? 'nowrap' : 'wrap'}>
      <Grid item py={6} px={{ xs: 3, lg: 6 }} xs={12} sm={12} lg={6} sx={{ bgcolor }}>
        {isXSmall && <Typography variant="h4">{title}</Typography>}
        {!isXSmall && <Typography variant="h3">{title}</Typography>}

        <ReactMarkdown
          children={description}
          components={{
            p: ({ children }) => (
              <Typography fontSize="18px" fontWeight={300} lineHeight="24px" mt={2}>
                {children}
              </Typography>
            ),
          }}
        />

        {links && (
          <Box mt={4}>
            {links.map((link) => (
              <PageLink key={link.label} {...link} />
            ))}
          </Box>
        )}
      </Grid>

      <Grid item xs={12} sm={12} lg={6}>
        <Box
          height="100%"
          minHeight="500px"
          sx={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${image})`,
          }}
        />
      </Grid>
    </Grid>
  );
}
