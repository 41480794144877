import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

export default function PageLink(props) {
  const { label, path } = props;

  return (
    <Box>
      <Typography
        to={path}
        component={Link}
        variant="h5"
        sx={{
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {label}&nbsp;&gt;
      </Typography>
    </Box>
  );
}
