import React from 'react';

import { Typography } from '@mui/material';

export default function MonthlyPriceSection(props) {
  const {
    startPrice,
    primaryPrice,
    secondaryPrice,
    contractMonths,
    promotionPeriod,
    currencySymbol = '€',
  } = props;

  // EXTRACT the integer and decimal parts of the price
  const integer = Math.floor(primaryPrice);
  const decimals = (primaryPrice % 1).toFixed(2).substring(2);

  return (
    <>
      <Typography component="span" variant="h4">
        {integer},<sup style={{ top: '-0.3em' }}>{decimals}</sup> {currencySymbol}{' '}
      </Typography>
      <Typography component="span" variant="h6">
        mtl.
      </Typography>

      <Typography fontSize="14px" fontWeight={300}>
        {promotionPeriod && secondaryPrice ? (
          `ab dem ${promotionPeriod + 1}. Monat: ${secondaryPrice} ${currencySymbol}`
        ) : (
          <>&nbsp;</>
        )}
      </Typography>
      <Typography fontSize="14px" fontWeight={300}>
        {contractMonths} Monate Laufzeit
      </Typography>
      <Typography fontSize="14px" fontWeight={300}>
        {startPrice},- {currencySymbol} Bereitstellungsentgeld
      </Typography>
    </>
  );
}
