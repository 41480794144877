import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate } from 'react-router-dom';

import Modal from 'portal-components/Modal';

import { Box, Stack, TextField, Typography } from '@mui/material';

import { requestHideModal } from '../../../actions/modal.actions';

function Login(props) {
  const { login, modal, icons, close, isUserLoggedIn, requestHideModal } = props;

  const navigate = useNavigate();

  // SET the local states
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // NAVIGATE to the customer personal area once users log in
  useEffect(() => {
    if (isUserLoggedIn) {
      close();
      navigate('/my-places');
    }
  }, [isUserLoggedIn]);

  // SUBMIT the login form if both fields are filled
  const submitForm = () => {
    if (!username || !password) return;

    login({ username, password });
  };

  return (
    <>
      <Box mr={2} height="20px" textAlign="right" sx={{ cursor: 'pointer' }} onClick={close}>
        <img height="100%" src={icons.close} alt="Close" />
      </Box>

      <Box margin="auto" maxWidth={360}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box width="28px">
            <img width="100%" src={icons.profile} alt="Profile" />
          </Box>

          <Box>
            <Typography variant="h5">Willkommen zurück!</Typography>
            <Typography fontSize="14px" fontWeight={300}>
              Bitte melden Sie sich mit Ihren Zugangsdaten an
            </Typography>
          </Box>
        </Box>

        <Stack my={2} spacing={2}>
          <TextField
            fullWidth
            size="small"
            placeholder="Email"
            sx={{ '& fieldset': { border: 'none' } }}
            InputProps={{ sx: { bgcolor: '#FFF' } }}
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            fullWidth
            size="small"
            type="password"
            placeholder="Passwort"
            sx={{ '& fieldset': { border: 'none' } }}
            InputProps={{ sx: { bgcolor: '#FFF' } }}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Stack>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link to="/request-password-reset" onClick={close}>
            <Typography fontSize="14px">Passwort vergesen? &gt;</Typography>
          </Link>
          <Typography variant="h5" onClick={submitForm} sx={{ cursor: 'pointer' }}>
            Login &gt;
          </Typography>
        </Box>
      </Box>

      {modal.showModal && modal.modalType === 'error' && (
        <Modal
          open
          negative
          dimmer="inverted"
          headerTitle="Error"
          action={() => requestHideModal()}
          contentText={
            modal.data === 401
              ? 'Emailadresse oder Passwort sind nicht korrekt.'
              : modal.data === 500
                ? 'Sieht so aus als ob hier etwas schief gelaufen ist. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Kundensupport.'
                : ''
          }
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ requestHideModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
