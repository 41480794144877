import React from 'react';

import { Box } from '@mui/material';

import Text from '../Text';
import Image from '../Image';
import Landing from '../Landing';
import Products from '../Products';
import Coverage from '../Coverage';
import Carousel from '../Carousel';
import ContactUs from '../ContactUs';
import TextImage from '../TextImage';
import TextIcons from '../TextIcons';
import TextLinks from '../TextLinks';
import PageHeader from '../PageHeader';

export default function Page(props) {
  const { data, coverage } = props;

  const blockMaxWidth = 1875;

  // Filter out blocks that should be full width
  const fullWidthBlockTypes = ['landing', 'pageHeader'];
  const fullWidthBlocks = data.blocks.filter((block) => fullWidthBlockTypes.includes(block.type));

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      {
        // Render full width blocks
        fullWidthBlocks.map((block, index) => {
          const key = `${block.type}-${index}`;
          const blockProps = { key, data: block };

          switch (block.type) {
            case 'landing':
              return <Landing {...blockProps} />;
            case 'pageHeader':
              return <PageHeader {...blockProps} />;
            default:
              return null;
          }
        })
      }

      <Box maxWidth={blockMaxWidth} width="100%">
        {
          // Render blocks that should only reach the maximum width
          data.blocks.map((block, index) => {
            const key = `${block.type}-${index}`;
            const blockProps = { key, data: block };

            switch (block.type) {
              case 'textLinks':
                return <TextLinks {...blockProps} />;
              case 'coverage':
                return <Coverage {...blockProps} {...coverage} />;
              case 'image':
                return <Image {...blockProps} />;
              case 'products':
                return <Products {...blockProps} />;
              case 'carousel':
                return <Carousel {...blockProps} />;
              case 'text':
                return <Text {...blockProps} />;
              case 'textImage':
                return <TextImage {...blockProps} />;
              case 'textIcons':
                return <TextIcons {...blockProps} />;
              case 'contactUs':
                return <ContactUs {...props} {...blockProps} />;
              case 'space':
                return <Box {...blockProps} height={block.height || '100px'} />;
              default:
                return null;
            }
          })
        }
      </Box>
    </Box>
  );
}
