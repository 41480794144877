import React from 'react';

import ReactMarkdown from 'react-markdown';

import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PageLink from '../../components/PageLink';

export default function TextIcons(props) {
  const { data } = props;
  const { title, links, icons, bgcolor, iconsBgcolor, description } = data;

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isXSmall = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container p={2} gap={1} wrap={isLarge ? 'nowrap' : 'wrap'}>
      <Grid item py={6} px={{ xs: 3, lg: 6 }} xs={12} sm={12} lg={6} sx={{ bgcolor }}>
        {isXSmall && (
          <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>
            {title}
          </Typography>
        )}
        {!isXSmall && <Typography variant="h3">{title}</Typography>}

        <ReactMarkdown
          children={description}
          components={{
            p: ({ children }) => (
              <Typography fontSize="18px" fontWeight={300} mt={2}>
                {children}
              </Typography>
            ),
          }}
        />

        {links && (
          <Box mt={4}>
            {links.map((link) => (
              <PageLink key={link.label} {...link} />
            ))}
          </Box>
        )}
      </Grid>

      {icons && (
        <Grid
          item
          p={2}
          xs={12}
          sm={12}
          lg={6}
          bgcolor={iconsBgcolor}
          minHeight={{ xs: '360px', lg: 'inherit' }}
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {icons.map(({ icon }, index) => (
              <React.Fragment key={index}>
                <img src={icon} alt="icon" style={{ maxWidth: isXSmall ? '300px' : '500px' }} />

                {index < icons.length - 1 && (
                  <Divider
                    flexItem
                    sx={{
                      borderBottomWidth: '2px',
                      borderColor: 'common.black',
                      marginTop: 6,
                      marginBottom: 6,
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
