import { createTheme } from '@mui/material/styles';

let customTheme = createTheme({
  palette: {
    primary: {
      main: '#18181A',
    },
    common: {
      black: '#18181A',
    },
    midGray: {
      main: '#B2B2BA',
    },
    lightGray: {
      main: '#E4E4ED',
    },
    purple: {
      main: '#C887FF',
      light: '#EBD7FF',
    },
    orange: {
      main: '#FF7841',
      light: '#FFE1D7',
    },
    green: {
      main: '#78DC46',
      light: '#DCFFC8',
    },
  },
  typography: {
    fontFamily: 'KHTeka',
    h1: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '58px',
      lineHeight: '56px',
    },
    h2: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
    },
    h3: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '48px',
    },
    h4: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '32px',
    },
    h5: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '21px',
    },
    h6: {
      fontFamily: 'KHTekaPro',
      fontWeight: 300,
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
});

customTheme = createTheme({
  ...customTheme,
});

export default customTheme;
