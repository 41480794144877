import React from 'react';

import { Link } from 'react-router-dom';

import { Box, styled, Divider, Typography } from '@mui/material';

import MainSection from './sections/Main';
import AddonsSection from './sections/Addons';
import DetailsSection from './sections/Details';
import HomedropSection from './sections/Homedrop';
import MonthlyPriceSection from './sections/MonthlyPrice';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: 'black',
  borderWidth: '1.5px',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function Product(props) {
  const {
    name,
    title,
    width,
    upload,
    download,
    bgcolor,
    tvPrice,
    startPrice,
    monthlyPrice,
    contractMonths,
    discountedMonths,
    landlineFlatPrice,
    installationPrice,
    currencySymbol = '€',
    discountedMonthlyPrice,
    discountedInstallationPrice,
  } = props;

  return (
    <Box textAlign="center" minWidth={width}>
      <Box p={2} mb={1} bgcolor={bgcolor}>
        <MainSection name={name} title={title} />

        <StyledDivider />

        <MonthlyPriceSection
          startPrice={startPrice}
          primaryPrice={discountedMonthlyPrice || monthlyPrice}
          secondaryPrice={monthlyPrice}
          contractMonths={contractMonths}
          promotionPeriod={discountedMonths}
          currencySymbol={currencySymbol}
        />

        <StyledDivider />

        <DetailsSection upload={upload} download={download} />

        <StyledDivider />

        <AddonsSection
          tvPrice={tvPrice}
          landlineFlatPrice={landlineFlatPrice}
          currencySymbol={currencySymbol}
        />

        <StyledDivider />

        <HomedropSection
          currencySymbol={currencySymbol}
          installationPrice={installationPrice}
          discountedInstallationPrice={discountedInstallationPrice}
        />
      </Box>

      <Box mb={2}>
        <Typography
          to="/coverage"
          component={Link}
          fontSize="20px"
          fontWeight={300}
          sx={{
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Verfügbarkeit prüfen&nbsp;&gt;
        </Typography>
      </Box>
    </Box>
  );
}
