import React from 'react';

import { Box, Typography } from '@mui/material';

export default function DetailsSection(props) {
  const { upload, download } = props;

  return (
    <>
      <Typography fontSize="18px" fontWeight={300} lineHeight="24px">
        Internet Flatrate
      </Typography>

      <Box mt={1}>
        <Typography fontSize="18px" fontWeight={300} lineHeight="24px">
          Bandbreite:
          <br />
          {download} Gbit/s Download
          <br />
          {upload} Gbit/s Upload
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography fontSize="18px" fontWeight={300} lineHeight="24px">
          VoIP (1 Sprachkanal) & Festnetz Flat
        </Typography>
      </Box>
    </>
  );
}
