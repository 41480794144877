import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { Modal, Header, Button } from 'semantic-ui-react';

import Form from './Form';
import Page from '../Page';

export default function ContactUs(props) {
  const { data, role, apiKey, apiUrl, networkOperator } = props;
  const { title, subtitle, blocks } = data;

  // SET the local states
  const [success, setSuccess] = useState(false);

  return (
    <Box>
      <Box maxWidth={920} margin="auto">
        <Box px={{ xs: 2, lg: 6 }}>
          <Typography variant="h3">{title}</Typography>
          <Typography fontSize="18px" fontWeight={300}>
            {subtitle}
          </Typography>

          {!(role === 'customer') && (
            <Box px={6} py={4} my={4} gap={2} display="flex" bgcolor="orange.main">
              <img src="/images/Icon-Profile.svg" alt="" style={{ width: '28px' }} />

              <Box>
                <Typography fontSize="18px" fontWeight={300}>
                  Sie sind bereits registriert?
                </Typography>
                <Typography fontSize="18px" fontWeight={300}>
                  Bitte helfen Sie uns Ihnen schnell helfen zu können, indem Sie sich anmelden.
                </Typography>
              </Box>
            </Box>
          )}

          <Form
            apiUrl={apiUrl}
            setSuccess={setSuccess}
            networkOperator={networkOperator}
            isUserLoggedIn={role === 'customer'}
            apiKey={role === 'autologin' ? '' : `?api_key=${apiKey}`}
          />
        </Box>

        <Box mt="120px">
          <Page data={{ blocks }} />
        </Box>

        <Modal open={success} size="small" dimmer="inverted">
          <Header as="h5" icon="send" content="Ihre Nachricht wurde gesendet" color="green" />

          <Modal.Content>
            Vielen Dank für Ihre Nachricht. Wir werden uns in Kürze bei Ihnen melden!
          </Modal.Content>

          <Modal.Actions>
            <Button positive content="Ok" onClick={() => setSuccess(false)} />
          </Modal.Actions>
        </Modal>
      </Box>
    </Box>
  );
}
