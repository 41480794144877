import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useFetch from 'use-http';

import ScrollToTop from '../components/ScrollToTop';
import { CookieBanner, injectGAScript } from '../components/CookieBanner';

import { loadLang } from '../actions/lang.actions';
import { userLang } from '../actions/userLang.actions';
import { toggleCustomerType } from '../actions/customerType.actions';
import { requestLogout, requestLoginData } from '../actions/login.actions';

import Header from './Header';
import Footer from './Footer';

import data from './data.json';
import getRoutes from './routes';

import './style.css';

const ROOT_PATH = '/';
const BRAND_NAME = 'OPIK';
const CMS_URL = 'https://cms-de1.lilaconnect.com/wp-json/wp/v2';

const App = (props) => {
  const {
    init,
    config,
    release,
    userLang,
    loadLang,
    languages,
    objectGroups,
    requestLogout,
    isUserLoggedIn,
    requestLoginData,
  } = props;

  const { logo, icons, header, footer } = data;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // INITIALIZE fetch to perform requests
  const { get, response } = useFetch('./');

  // SET the local states
  const [showLogin, setShowLogin] = useState(false);
  const [locale] = useState(config.languages[0].id);
  const [finalMessages, setFinalMessages] = useState(languages[locale]);

  // NAVIGATE to the customer personal area if the user is logged in
  // otherwise, SHOW the login
  const onLoginClick = () => {
    if (isUserLoggedIn) {
      navigate('/my-places');
    } else {
      setShowLogin(true);
    }
  };

  // GET the messages of a certain language
  const getMessages = async () => {
    await get(`compiled-lang/${locale}.json`);

    if (response.ok) {
      loadLang({ [locale]: response.data });
    }
  };

  // ON component MOUNT
  useEffect(() => {
    // INJECT on mount the tracking script if users already gave the cookie consent
    if (localStorage.getItem('consent')) {
      injectGAScript(config.google_tm_container_id);
    }

    // COMPARE browser and config languages
    config.languages.forEach((el) => {
      if (navigator.language.substring(0, 2) === el.id) {
        userLang(el.id);
      } else {
        userLang(config.languages[0].id);
      }
    });

    // LOAD the messages
    getMessages();
  }, []);

  // SET the final messages
  useEffect(() => {
    if (Object.keys(languages).length > 0 && locale) {
      // setFinalMessages(override(finalMessages, languages[locale], true));
      setFinalMessages({ ...finalMessages, ...languages[locale] });
    }
  }, [languages, locale]);

  // LOGOUT users
  const handleLogout = () => {
    requestLogout();

    sessionStorage.removeItem('api_key');
    navigate(config.base_url);
  };

  // SHOW a loading spinner until data is available
  if (!init || !init.role || !finalMessages) {
    return <Loader active style={{ color: 'var(--primaryColor)' }} />;
  }

  // DEFINE the properties to pass to the routes
  let routeProps = {
    init,
    config,
    locale,
    role: init.role,
    apiKey: init.api_key,
    cities: objectGroups,
    brandName: BRAND_NAME,
    cmsURL: CMS_URL,
    apiUrl: config.api,
    baseURL: config.base_url,
    onLoginClick: onLoginClick,

    networkOperator: config.network_operator,
    location: {
      ...location,
      query: Object.fromEntries([...searchParams]),
    },
  };

  return (
    <IntlProvider locale={locale} messages={finalMessages}>
      <div id="app">
        <ScrollToTop />

        <Header
          logo={logo}
          icons={icons}
          sitemap={header.sitemap}
          links={header.links}
          big={location.pathname === ROOT_PATH}
          light={header.lightLogoPages.includes(location.pathname)}
          login={requestLoginData}
          logout={location.pathname === '/my-places' && handleLogout}
          isUserLoggedIn={isUserLoggedIn}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          onLoginClick={onLoginClick}
        />

        <div className="page">{getRoutes(routeProps)}</div>

        <Footer logo={logo.dark} release={release} links={footer.links} />

        <CookieBanner
          locale={locale}
          baseURL={config.base_url}
          googleTagManagerContainerId={config.google_tm_container_id}
        />
      </div>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  init: state.init,
  languages: state.lang,
  userLang: state.userLang,
  customerType: state.customerType,
  isUserLoggedIn: state.init.api_key !== undefined,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadLang,
      userLang,
      requestLogout,
      requestLoginData,
      toggleCustomerType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
