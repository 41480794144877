import React from 'react';

import BigCoverage from './BigCoverage';
import SmallCoverage from './SmallCoverage';

export default function Coverage(props) {
  const { data } = props;

  if (data.size === 'small') {
    return <SmallCoverage {...props} />;
  }

  return <BigCoverage {...props} />;
}
