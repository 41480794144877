import React from 'react';

import { Box } from '@mui/material';

export default function Image(props) {
  const { data } = props;
  const { image } = data;

  return (
    <Box p={2}>
      <Box
        height="100vh"
        minHeight="360px"
        maxHeight="930px"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${image})`,
        }}
      />
    </Box>
  );
}
