import React from 'react';

import { Box } from '@mui/material';

import TitleStripe from './TitleStripe';

export default function Landing(props) {
  const { data } = props;
  const { title, backgroundImage } = data;

  return (
    <Box
      width="100vw"
      height="100vh"
      position="relative"
      sx={{
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <TitleStripe title={title} />
    </Box>
  );
}
