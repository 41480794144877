import React from 'react';

import Page from '../../blocks/Page';

import data from '../../content/pages/home.json';
import productsData from '../../content/pages/products.json';

export default function HomePage(props) {
  const { role, config, onLoginClick } = props;

  // GET the products block from the products page data
  const {
    blocks: [productsBlock],
  } = productsData;

  // INJECT the products block into the home page data by searching for the slot block
  const newData = {
    ...data,
    blocks: data.blocks.map((block) => (block.type === 'slot' ? productsBlock : block)),
  };

  return (
    <Page
      data={newData}
      coverage={{
        role,
        onLoginClick,
        api: config.api,
      }}
    />
  );
}
