import React, { useRef, useState } from 'react';

import Markdown from 'react-markdown';

import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ProductCard from './ProductCard';

const BUSINESS = 'business';
const RESIDENTIAL = 'residential';

export default function Product(props) {
  const { data } = props;
  const { title, subtitle, products, remarks } = data;

  const productCardsRef = useRef(null);

  // SET the local states
  const [scroll, setScroll] = useState(0);
  const [consumerType, setConsumerType] = useState(RESIDENTIAL);

  const toggleConsumerType = () => {
    setConsumerType(consumerType === RESIDENTIAL ? BUSINESS : RESIDENTIAL);
  };

  const onProductClick = (index) => {
    const left = index * productCardWidth + 8 * index;

    setScroll(index);
    productCardsRef.current.scrollTo({ left, top: 0, behavior: 'smooth' });
  };

  // SET the card and gap width
  const productCardWidth = 260;
  const productCardsWidth =
    // Product cards width
    productCardWidth * products[consumerType].length +
    // Gap between product cards
    8 * (products[consumerType].length - 1);

  const theme = useTheme();
  const isXSmall = !useMediaQuery(theme.breakpoints.up('sm'));

  const productsFitInScreen = !useMediaQuery(`(min-width: ${productCardsWidth}px)`);

  return (
    <Box>
      <Box p={2} textAlign="center">
        <Typography variant={isXSmall ? 'h4' : 'h1'}>{title}</Typography>
        <Markdown
          children={subtitle}
          components={{
            p: ({ children }) => <Typography variant="h6">{children}</Typography>,
          }}
        />
      </Box>

      <Box my={4} gap={2} display="flex" justifyContent="center">
        <StyledButton
          variant="text"
          onClick={toggleConsumerType}
          sx={{ color: consumerType === RESIDENTIAL ? 'primary.main' : 'midGray.main' }}
        >
          Privatkunden
        </StyledButton>
        <StyledButton
          variant="text"
          onClick={toggleConsumerType}
          sx={{ color: consumerType === BUSINESS ? 'primary.main' : 'midGray.main' }}
        >
          Geschäftskunden
        </StyledButton>
      </Box>

      {productsFitInScreen && (
        <Stack my={4} direction="row" gap={2} justifyContent="center">
          {products[consumerType].map((product, index) => (
            <Box
              key={index}
              width={10}
              height={10}
              borderRadius={5}
              sx={{ cursor: 'pointer' }}
              onClick={() => onProductClick(index)}
              bgcolor={index === scroll ? 'common.black' : 'lightGray.main'}
            />
          ))}
        </Stack>
      )}

      <Box
        px={2}
        gap={1}
        margin="auto"
        display="flex"
        maxWidth="100vw"
        sx={{ overflowX: 'hidden' }}
        ref={productCardsRef}
        width={productCardsWidth + 32}
      >
        {products[consumerType].map((product) => (
          <ProductCard key={product.id} width={productCardWidth} {...product} />
        ))}
      </Box>

      <Box px={2} m="auto" mt={6} maxWidth={productCardsWidth} textAlign="center">
        <Markdown
          children={remarks}
          components={{
            p: ({ children }) => (
              <Typography fontSize="14px" fontWeight={300} lineHeight="18px" my={2}>
                {children}
              </Typography>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 300,
  textTransform: 'none',
}));
