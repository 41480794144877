import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Grid, Typography } from '@mui/material';

import CoverageCheckForm from '../CoverageCheckForm';

export default function Coverage(props) {
  const { api, role, data, onLoginClick } = props;
  const { title, subtitle } = data;

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    const object = event.target.value;
    navigate(`/coverage?object=${object.id}`);
  };

  return (
    <Grid container p={2} gap={1} wrap={isLarge ? 'nowrap' : 'wrap'}>
      <Grid item xs={12} sm={12} lg={6} sx={{ bgcolor: 'purple.main' }}>
        <Box py={6} px={{ xs: 3, lg: 6 }}>
          <Typography variant={isLarge ? 'h1' : 'h4'} sx={{ wordBreak: 'break-word' }}>
            {title}
          </Typography>

          <Typography fontSize="18px" fontWeight={300} mt={2}>
            {subtitle}
          </Typography>
        </Box>
      </Grid>

      <Grid item p={4} xs={12} sm={12} lg={6} sx={{ bgcolor: 'purple.light' }}>
        <CoverageCheckForm
          api={api}
          role={role}
          onLoginClick={onLoginClick}
          handleFormSubmit={handleFormSubmit}
        />
      </Grid>
    </Grid>
  );
}
