import { useState } from 'react';

export default function useCoverageCheck(props) {
  const { api, networkOperator } = props;

  // SET the local states
  const [error, setError] = useState();
  const [cities, setCities] = useState();
  const [streets, setStreets] = useState();
  const [streetNumbers, setStreetNumbers] = useState();

  const [selectedStreet, setSelectedStreet] = useState('');
  const [selectedCity, setSelectedCity] = useState(undefined);

  const [streetLoading, setStreetLoading] = useState(false);
  const [streetNumberLoading, setStreetNumberLoading] = useState(false);

  function resetForm() {
    setCities(undefined);
    setStreets(undefined);
    setStreetNumbers(undefined);

    setSelectedStreet('');
    setSelectedCity(undefined);

    setError(undefined);
  }

  // Search for ZIP/City every time the search field changes
  function handleCitySearch(event) {
    const zip = event.target.value;

    resetForm();

    if (zip) {
      fetch(`${api}/postal_code_city?per_page=30&q=label:/${zip}/i&sort=label`)
        .then((response) => {
          if (response.ok) return response.json();
          else throw Error(response.statusText);
        })
        .then(({ data }) => setCities(data))
        .catch(() => {
          console.log('Error fetching ZIP/City');
        });
    }
  }

  // Retrieve streets once a ZIP/City has been selected
  function handleStreetSearch(event, value) {
    const { city, postal_code: postalCode } = value;

    setStreetLoading(true);
    setSelectedCity({ city, postalCode });

    fetch(`${api}/report/object_street?postal_code=${postalCode}&city=${city}&per_page=1000`)
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response.statusText);
      })
      .then(({ data }) => {
        if (data.length === 0) throw Error('No results found');

        setStreets(data);
        setStreetLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setStreetLoading(false);
      });
  }

  function handleStreetNumberSearch(event) {
    const street = event.target.value;

    const { city, postalCode } = selectedCity;

    setStreetNumberLoading(true);
    setSelectedStreet(street);

    fetch(
      `${api}/report/object_street_address` +
        `?postal_code=${postalCode}&city=${city}&network_operator=${networkOperator}&class_code=!RESIDENCE` +
        `&object_type=Residential,Business&street=${street}&object_number=/-01$/&per_page=1000` +
        '&fields=id,class_code,object_type,build_status,object_group,postal_code,city,province,street,street_number,apartment_number,object_group.external_url',
    )
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response.statusText);
      })
      .then(({ data }) => {
        if (data.length === 0) throw Error('No results found');

        setStreetNumbers(
          data.map((object) => ({
            id: object.id,
            classCode: object.class_code,
            objectType: object.object_type,
            buildStatus: object.build_status,
            objectGroup: object.object_group,
            postalCode: object.postal_code,
            street: object.street,
            streetNumber: object.street_number,
            city: object.city,
            province: object.province,
            country: 'Germany',
            streetAddress: `${object.street} ${object.street_number}`,
            address: `${object.street} ${object.street_number}${
              object.apartment_number ? `, ${object.apartment_number}` : ''
            }, ${object.postal_code} ${object.city}`,
            externalUrl: object['object_group.external_url'],
          })),
        );
        setStreetNumberLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setStreetNumberLoading(false);
      });
  }

  return {
    error,
    cities,
    streets,
    resetForm,
    streetNumbers,
    selectedStreet,
    streetLoading,
    streetNumberLoading,
    handleCitySearch,
    handleStreetSearch,
    handleStreetNumberSearch,
  };
}
