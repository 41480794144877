import React, { useRef, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import useFetch from 'use-http';

import { Form } from 'formsy-semantic-ui-react';
import { Grid, Label, Divider } from 'semantic-ui-react';

export default function ContactUsForm(props) {
  const { apiUrl, apiKey, setSuccess, networkOperator, isUserLoggedIn } = props;

  // SET a ref for the form
  const formRef = useRef(null);

  const errorString = 'Pflichtfeld';
  const validationErrors = { isNotEmptyString: errorString };

  const isNotEmptyString = (values, value) => value !== '';

  // SET the local states
  const [places, setPlaces] = useState(undefined);
  const [customer, setCustomer] = useState(undefined);

  // INITIALIZE fetch to perform requests
  const { get, response, loading } = useFetch(apiUrl, { cachePolicy: 'no-cache' });

  const getCustomerAndPlaces = async () => {
    // GET place data
    await get(`/place${apiKey}`);
    setPlaces(response.ok ? response.data.data : []);

    // GET customer data
    await get(`/customer${apiKey}`);
    setCustomer(response.ok ? response.data.data[0] : []);
  };

  useState(() => {
    if (isUserLoggedIn) {
      getCustomerAndPlaces(apiUrl);
    }
  }, []);

  let objectsOptions;

  if (isUserLoggedIn && places) {
    objectsOptions = places.map(
      ({ id, object, street, street_number, postal_code, city, province }) => ({
        key: id,
        value: object.object_number,
        text: [street, street_number, postal_code, city, province].filter(Boolean).join(', '),
      }),
    );
  }

  const onValidSubmit = (formData) => {
    let invalid = false;

    for (var key in formData) {
      if (!formData[key]) {
        formRef.current.updateInputsWithError({
          [key]: errorString,
        });
        invalid = true;
      }
    }
    if (invalid) return;

    let requestUrl = `${apiUrl}/api/email`;
    if (isUserLoggedIn) {
      requestUrl = `${apiUrl}/api/email${apiKey}`;
    }

    const body = isUserLoggedIn
      ? // Logged in
        `customer_number: ${customer.customer_number}\n` +
        `object_number: ${formData.object_number}\n` +
        `message: ${formData.message}\n` +
        `portal_url: ${window.location.origin}\n\n`
      : // Not logged in
        `name: ${formData.name}\n` +
        `email: ${formData.email}\n` +
        `phone: ${formData.phone}\n` +
        `message: ${formData.message}\n` +
        `portal_url: ${window.location.origin}\n\n`;

    fetch(requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        subject: 'Contact Us Form Submission',
        body: body,
        network_operator: networkOperator,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setSuccess(true);
        } else {
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .catch((error) => console.error(error));
  };

  const errorLabel = <Label color="red" basic pointing />;

  if (loading) {
    return <div style={{ paddingTop: '50vh', position: 'relative' }}>Loading...</div>;
  }

  return (
    <Box my={6}>
      <Form ref={(el) => (formRef.current = el)} onValidSubmit={onValidSubmit}>
        <Grid stackable columns={2}>
          {isUserLoggedIn && (
            <Grid.Row>
              <Grid.Column>
                <Divider hidden />
                <Form.Field
                  required
                  style={objectsOptions?.length === 1 ? { height: 0, visibility: 'hidden' } : {}}
                >
                  <Form.Dropdown
                    selection
                    name="object_number"
                    options={objectsOptions || []}
                    placeholder="Wählen Sie eine Adresse"
                    value={objectsOptions && objectsOptions.length === 1 && objectsOptions[0].value}
                    label="Adresse"
                    errorLabel={errorLabel}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}

          {!isUserLoggedIn && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field required>
                    <Form.Input
                      name="name"
                      type="text"
                      label="Name"
                      errorLabel={errorLabel}
                      validationErrors={validationErrors}
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column>
                  <Form.Field required>
                    <Form.Input
                      name="email"
                      type="text"
                      label="E-Mail Adresse"
                      errorLabel={errorLabel}
                      validations={{ isEmail: true, isNotEmptyString }}
                      validationErrors={{
                        ...validationErrors,
                        isEmail: 'Email not valid',
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form.Field required>
                    <Form.Input
                      name="phone"
                      type="text"
                      label="Telefon"
                      errorLabel={errorLabel}
                      validations={{ isNumeric: true, isNotEmptyString }}
                      validationErrors={{
                        ...validationErrors,
                        isNumeric: 'Phone not valid',
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </>
          )}

          <Grid.Column width={16}>
            <Form.Field required>
              <Form.TextArea
                name="message"
                label="Nachricht"
                rows="6"
                style={{ resize: 'none' }}
                errorLabel={errorLabel}
                validations={{ isNotEmptyString }}
                validationErrors={validationErrors}
              />
            </Form.Field>

            <Divider hidden />

            <Button type="submit" sx={{ textTransform: 'none' }}>
              <Typography variant="h5">Senden&nbsp;&gt;</Typography>
            </Button>
          </Grid.Column>
        </Grid>
      </Form>
    </Box>
  );
}
