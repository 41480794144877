import React from 'react';

import { Box, Typography } from '@mui/material';

export default function HomedropSection(props) {
  const { currencySymbol, installationPrice, discountedInstallationPrice } = props;

  return (
    <>
      <Typography fontSize="18px" fontWeight={300} lineHeight="24px">
        Kosten für den Hausanschluss****
      </Typography>

      <Box my={1}>
        <Typography variant="h4">
          {discountedInstallationPrice},- {currencySymbol}***
        </Typography>

        <Typography fontSize="15px" fontWeight={300} lineHeight="24px">
          statt {installationPrice},- {currencySymbol}
        </Typography>
      </Box>

      <Typography fontSize="22px" fontWeight={300} lineHeight="24px">
        Baukosten gratis**
      </Typography>
    </>
  );
}
