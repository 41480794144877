import React from 'react';

import ReactMarkdown from 'react-markdown';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Text(props) {
  const { data } = props;
  const { title, description } = data;

  const theme = useTheme();
  const isXSmall = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box py={6} px={{ xs: 3, lg: 6 }} sx={{ bgcolor: 'lightGray.main' }}>
      {isXSmall && <Typography variant="h4">{title}</Typography>}
      {!isXSmall && <Typography variant="h3">{title}</Typography>}

      <ReactMarkdown
        children={description}
        components={{
          p: ({ children }) => (
            <Typography variant="h6" mt={2}>
              {children}
            </Typography>
          ),
        }}
      />
    </Box>
  );
}
