import React from 'react';

import { Box, Typography } from '@mui/material';

export default function PageHeader(props) {
  const { data } = props;
  const { title, bgcolor, backgroundImage } = data;

  return (
    <Box>
      <Box
        width="100vw"
        height="560px"
        position="relative"
        sx={{
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <Box px={5} bgcolor={bgcolor} height="60px" display="flex" alignItems="center">
        <Typography fontSize="24px" fontWeight={300}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
}
