import React from 'react';

import { Box, Typography } from '@mui/material';

export default function AddonsSection(props) {
  const { tvPrice, landlineFlatPrice, currencySymbol } = props;

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      {landlineFlatPrice && (
        <Box>
          <Typography fontSize="18px" fontWeight={300} lineHeight="24px">
            + Weiterer Sprachkanal
            <br />
            inkl. Festnetz Flat
            <br />
            {landlineFlatPrice},- {currencySymbol} mtl.
          </Typography>
        </Box>
      )}

      {tvPrice && (
        <Box>
          <Typography fontSize="18px" fontWeight={300} lineHeight="24px">
            + TV-Option
            <br />
            ab {tvPrice} {currencySymbol} mtl.
          </Typography>
          <img src="/images/waipuTv.svg" alt="Waipu TV" style={{ width: '88px' }} />
        </Box>
      )}
    </Box>
  );
}
