import React, { useEffect, useState } from 'react';

import {
  Box,
  Stack,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import Modal from 'portal-components/Modal';

import useCoverageCheck from './useCoverageCheck';

export default function CoverageCheckForm(props) {
  const { api, role, onLoginClick, networkOperator, handleFormSubmit } = props;

  const {
    error,
    cities,
    streets,
    streetNumbers,
    selectedStreet,
    streetLoading,
    streetNumberLoading,
    resetForm,
    handleCitySearch,
    handleStreetSearch,
    handleStreetNumberSearch,
  } = useCoverageCheck({ api, networkOperator });

  // SET the local states
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (error !== undefined) {
      setIsModalOpen(true);
    }
  }, [error]);

  // Some properties
  const inputProps = {
    sx: { bgcolor: '#FFF' },
  };
  const textFieldSx = { '& fieldset': { border: 'none' } };

  // CREATE a loader
  const loader = (
    <InputAdornment position="start">
      <CircularProgress size={20} />
    </InputAdornment>
  );

  return (
    <>
      <Stack spacing={2}>
        <Autocomplete
          fullWidth
          size="small"
          disablePortal
          disableClearable
          onChange={handleStreetSearch}
          options={cities || []}
          noOptionsText="Geben Sie Ihre PLZ/Ort ein"
          renderInput={(params) => (
            <TextField
              {...params}
              sx={textFieldSx}
              placeholder="PLZ/Ort"
              onChange={handleCitySearch}
              InputProps={{ ...params.InputProps, ...inputProps }}
            />
          )}
        />

        <TextField
          select
          fullWidth
          size="small"
          sx={textFieldSx}
          value={selectedStreet}
          disabled={streets === undefined}
          InputProps={{
            ...inputProps,
            startAdornment: streetLoading ? loader : null,
          }}
          onChange={handleStreetNumberSearch}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selected) => (selected === '' ? 'Straße' : selected),
          }}
        >
          <MenuItem disabled value="">
            <em>Straße</em>
          </MenuItem>

          {streets?.map((street) => (
            <MenuItem key={street} value={street}>
              {street}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          fullWidth
          size="small"
          defaultValue=""
          sx={textFieldSx}
          disabled={streetNumbers === undefined}
          InputProps={{
            ...inputProps,
            startAdornment: streetNumberLoading ? loader : null,
          }}
          onChange={handleFormSubmit}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selected) => (selected === '' ? 'Hausnummer' : selected.streetNumber),
          }}
        >
          <MenuItem disabled value="">
            <em>Hausnummer</em>
          </MenuItem>

          {streetNumbers?.map((streetNumber) => (
            <MenuItem key={streetNumber.id} value={streetNumber}>
              {streetNumber.streetNumber}
            </MenuItem>
          ))}
        </TextField>

        {role !== 'customer' && (
          <Box display="flex" alignItems="center" gap={1}>
            <img src="/images/Icon-Profile.svg" alt="" style={{ width: '14px' }} />

            <Typography fontSize="14px" fontWeight={300}>
              Sie haben bereits ein Kundenkonto?{' '}
            </Typography>

            <Box
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={onLoginClick}
            >
              Hier einloggen&nbsp;&gt;
            </Box>
          </Box>
        )}
      </Stack>

      <Modal
        dimmer="inverted"
        positive={false}
        negative={false}
        open={isModalOpen}
        mainButtonLabel="Ok"
        action={() => {
          resetForm();
          setIsModalOpen(false);
        }}
        headerTitle="Diese Adresse befindet sich leider nicht in unserem momentanen Ausbaugebiet."
        contentText="Es tut uns leid, Ihre Adresse liegt nicht in unserem momentanen Ausbaugebiet. Für weitere Informationen senden Sie uns bitte eine E-Mail an kontakt@opik.de"
      />
    </>
  );
}
