import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PageLink from '../../../components/PageLink';
import CoverageCheckForm from '../CoverageCheckForm';

export default function BigCoverage(props) {
  const { data, api, role, onLoginClick, networkOperator, handleFormSubmit } = props;

  const { title, links, icon, bgcolor, subtitle, iconBgcolor } = data;

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isXSmall = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container p={2} gap={2} wrap={isLarge ? 'nowrap' : 'wrap'} width="100%">
      <Grid item py={6} px={{ xs: 3, lg: 6 }} xs={12} sm={12} lg={6} sx={{ bgcolor }}>
        {isXSmall && <Typography variant="h4">{title}</Typography>}
        {!isXSmall && <Typography variant="h3">{title}</Typography>}

        <Typography fontSize="18px" fontWeight={300} my={2}>
          {subtitle}
        </Typography>

        <CoverageCheckForm
          api={api}
          role={role}
          onLoginClick={onLoginClick}
          networkOperator={networkOperator}
          handleFormSubmit={handleFormSubmit}
        />

        {links && (
          <Box mt={4} textAlign="right">
            {links.map((link) => (
              <PageLink key={link.label} {...link} />
            ))}
          </Box>
        )}
      </Grid>

      <Grid
        item
        p={2}
        xs={12}
        sm={12}
        lg={6}
        bgcolor={iconBgcolor}
        minHeight={{ xs: '360px', lg: 'inherit' }}
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={icon} alt="icon" style={{ maxWidth: '500px' }} />
        </Box>
      </Grid>
    </Grid>
  );
}
