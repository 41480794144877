import React from 'react';

import { Box, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function TitleStripe(props) {
  const { title } = props;

  const [topLine, bottomLine] = title.split(' ');

  const theme = useTheme();
  const isXSmall = !useMediaQuery(theme.breakpoints.up('sm'));

  if (isXSmall) {
    return (
      <Box position="absolute" bottom="10%">
        <Box width="100vw" bgcolor="#FF7841" height="38px">
          <Typography flexGrow={1} variant="h4" marginLeft="45%">
            {topLine}
          </Typography>
        </Box>

        <Box width="100vw">
          <Typography color="#FFF" variant="h4" marginLeft="45%">
            {bottomLine}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box position="absolute" bottom="10%">
      <Box width="100vw" bgcolor="#FF7841" height="60px">
        <Typography flexGrow={1} variant="h2" marginLeft={{ sm: '45%', lg: '60%' }}>
          {topLine}
        </Typography>
      </Box>

      <Box width="100vw">
        <Typography color="#FFF" variant="h2" marginLeft={{ sm: '45%', lg: '60%' }}>
          {bottomLine}
        </Typography>
      </Box>
    </Box>
  );
}
