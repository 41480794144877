import React from 'react';

import { Box, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

export default function Footer(props) {
  const { logo, links } = props;

  return (
    <Box
      p={2}
      m="auto"
      mt={4}
      maxWidth={1875}
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      {links.map((column, index) => (
        <Box key={index}>
          {column.map((link) => (
            <Box key={link.label} py={1}>
              <Typography
                to={link.path}
                component={Link}
                variant="h6"
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {link.label}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}

      <Box mt={{ xs: 10, sm: 0 }} textAlign={{ sx: 'left', sm: 'right' }}>
        <img className="logo" src={logo} alt="" style={{ maxWidth: '255px' }} />

        <Typography mt={2} fontSize="16px" fontWeight={300}>
          © {new Date().getFullYear()} VNEXT AB, Alle Rechte vorbehalten.
        </Typography>
      </Box>
    </Box>
  );
}
