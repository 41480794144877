import React from 'react';

import ReactMarkdown from 'react-markdown';

import { Typography } from '@mui/material';

export default function MainSection(props) {
  const { name, title } = props;

  return (
    <>
      <ReactMarkdown
        children={name}
        components={{
          p: ({ children }) => (
            <Typography fontSize="21px" fontWeight={300} lineHeight="22px">
              {children}
            </Typography>
          ),
        }}
      />
      <Typography variant="h3">{title}</Typography>
    </>
  );
}
