import React from 'react';

import ReactMarkdown from 'react-markdown';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PageLink from '../../components/PageLink';

export default function TextLinks(props) {
  const { data } = props;
  const { title, description, links } = data;

  const theme = useTheme();
  const isXSmall = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container spacing={5} minHeight={340} p={{ xs: 2, sm: 5 }}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        {isXSmall && <Typography variant="h4">{title}</Typography>}
        {!isXSmall && <Typography variant="h1">{title}</Typography>}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={4}>
        <ReactMarkdown
          children={description}
          components={{
            p: ({ children }) => (
              <Typography fontSize="18px" fontWeight={300} lineHeight="24px" mt={2}>
                {children}
              </Typography>
            ),
          }}
        />
      </Grid>

      {links && (
        <Grid item xs={12} sm={12} md={12} lg={2} textAlign={{ xs: 'left', lg: 'right' }}>
          {links.map((link) => (
            <PageLink key={link.path} label={link.label} path={link.path} />
          ))}
        </Grid>
      )}
    </Grid>
  );
}
